/* Styling Checkbox Starts https://medium.com/mobile-app-developers/styling-checkbox-css-tips-e103c54ea1c7 */
.checkbox-label {
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
    height: 24px;
    width: 100%;
    clear: both;
}

.checkbox-label input {
    opacity: 0;
    margin-right: 16px!important;
    cursor: pointer;
}

.checkbox-label .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 24px;
    width: 24px;
    background-color: #DFE2E6;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    border: 2px solid #DFE2E6;
}


.checkbox-label input:checked ~ .checkbox-custom {
    background-color: #009BFF;
    border-radius: 5px;
    transform: rotate(0deg) scale(1);
    opacity:1;
    border: 2px solid #009BFF;
}


.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid #FFFFFF;
    border-width: 0 3px 3px 0;
    transform: rotate(0deg) scale(0);
    opacity:1;
    transition: all 0.3s ease-out;
}


.checkbox-label input:checked ~ .checkbox-custom::after {
  transform: rotate(45deg) scale(1);
  opacity:1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #FFFFFF;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

