.marker {
  height: 16px;
  width: 16px;
  background: #333333;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  box-shadow: inset 0 4px rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* animation: pulse 2.75s infinite; */
  z-index: 99999;
}

/* 
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #d3f6ff, 0 0 0 0 #333333;
  }
  50% {
    box-shadow: 0 0 0 50px rgba(211, 246, 255, 0); 
  }
  100% {
    box-shadow: 0 0 0 50px rgba(211, 246, 255, 0);
  }
} */
