.react-geocoder {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
}

.react-geocoder input {
  padding: 4px 8px;
  background: #FFFFFF;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #333;
  line-height: 26px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.react-geocoder > * {
  width: 100%;
}

.react-geocoder-results {
  position: absolute;
  top: 48px;
  background: #fff;
  border: 1px solid #f3f3f3;
  width: 100%;
  padding: 9px 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.react-geocoder-results > * {
  margin-bottom: 8px;
  padding-bottom:8px;
}

.react-geocoder-results > *:hover {
  background:#f3f3f3;
}


.react-geocoder-results > :last-child {
  border-bottom: none;
}

